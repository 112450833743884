import Link from 'next/link';
import React, {
  FC,
  type MouseEventHandler,
  PropsWithChildren,
  ReactNode,
  memo,
  useCallback,
} from 'react';
import { isExternalURL } from '@bridebook/toolbox/src/utils/strings';
import { A, Box } from '@bridebook/ui';
import { closeAllTooltips } from '@bridebook/ui/src/components/bbcommon/tooltip/tooltip';
import { styles } from '../popover-menu.style';

interface ItemProps extends PropsWithChildren {
  onClick?: MouseEventHandler<ReactNode>;
  link?: string;
  icon?: ReactNode;
  isSelected?: boolean;
}

const PopoverMenuItemComp: FC<ItemProps> = ({ children, icon, onClick, link, isSelected }) => {
  const _onClick: MouseEventHandler<ReactNode> = useCallback(
    (e) => {
      if (onClick) {
        onClick(e);
        closeAllTooltips();
      }
    },
    [onClick],
  );

  const isInteractive = Boolean(onClick || link);

  const content = (
    <Box
      px={3}
      py={icon ? 2 : 3} // Icon wrapper is higher than one line of text, acting as a fake padding
      style={styles.itemWrap({ isHighlighted: isSelected, isBold: isSelected, isInteractive })}
      onClick={_onClick}
      data-name="popover-menu-item">
      {icon && <Box style={styles.iconWrap}>{icon}</Box>}
      <Box style={styles.content}>{children}</Box>
    </Box>
  );

  return link ? (
    isExternalURL(link) ? (
      <A href={link} target="_blank" noUnderline color="space" w="100%">
        {content}
      </A>
    ) : (
      <Link href={link}>
        <A noUnderline w="100%">
          {content}
        </A>
      </Link>
    )
  ) : (
    content
  );
};

/**
 * Base component for every item in the popover menu.
 * Provides default padding and hover effect, even if the content is more complex.
 *
 * For simple text menus, it provides a link or onClick handler, with an optional icon.
 */
export const PopoverMenuItem = memo(PopoverMenuItemComp);
