import { equals, keys, map, merge, omit, pick, pickBy, uniq } from 'ramda';
import { TypedUseSelectorHook, useSelector, useStore } from 'react-redux';
import { Store } from 'redux';
import type { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import type { IPhoto } from '@bridebook/models/source/models/Suppliers/Photos.types';
import { getExt } from '@bridebook/toolbox/src/getExt';
import { IEnquiryWeddingSummarySupplier } from '@bridebook/toolbox/src/supplier-wedding-manager';
import type { Slug } from '@bridebook/toolbox/src/types';
import { isDefined } from '@bridebook/toolbox/src/utils/type-guards';
import { env } from 'lib/env';
import { IApplicationState } from 'lib/types';
import { Endorsement } from './endorsements/types';
import { IFeedbackSerialized } from './feedback/types';
import { IGetListedFields } from './get-listed/types';
import { IRecommendationRender } from './recommendations/actions/update-recommendations-done';

export const imgixBaseURL = 'https://images.bridebook.com';
export const imgixBBUsersURL = 'https://bridebook-users.imgix.net';
export const cloudBaseURL = 'https://cloud.bridebook.co.uk';
export const docsBaseURL = cloudBaseURL + '/docs';

// @ts-expect-error
export const mergeClean = (a, b) => merge(a, pick(keys(a), b || {}));

export function friendlyUrl({
  name,
  address,
}: {
  name: ISupplier['name'];
  address?: ISupplier['address'];
}) {
  const nameProp = `${name}-` || '';
  const townProp = `${address?.city}-` || '';
  const countyProp = `${address?.adminArea?.[0]}` || '';
  return `${nameProp}${townProp}${countyProp}`
    .replace(/-undefined/g, '')
    .replace(/-false/g, '')
    .replace(/[^a-z0-9]/gi, '-')
    .replace(/-+/g, '-')
    .toLowerCase();
}

const preparePhone = (phone: string) => phone.replace(/ |-/g, '');

export const getCategories = (list: IRecommendationRender[]) =>
  uniq(list.map((item) => item.type?.[0]).filter(Boolean))
    .sort()
    .reverse();

export function cleanFieldsForValidateStep1(fields: Pick<IGetListedFields, 'phone' | 'website'>) {
  const fieldsObject = JSON.parse(JSON.stringify(fields)) as IGetListedFields;
  const { phone, website } = fieldsObject;
  const cleanPhone = preparePhone(phone);
  fieldsObject.phone = cleanPhone.substring(cleanPhone.length - 9);
  fieldsObject.website = website.replace(/^(?:\w+:[/][/])?(?:ww+[.])?([^/]+)(?:.*)$/i, '');
  return fieldsObject;
}

export const filterByType = (category: string, items: IRecommendationRender[]) =>
  items.filter((i) => i?.type?.[0] === category);

export function isBooked(items: { booked: boolean }[]) {
  return items.filter((item) => item.booked);
}

export function getDefaultImage(slug: Slug): string {
  const map: Record<Slug, string> = {
    beauty: 'initial/beauty/beauty-bride-black-and-white-pixabay',
    cakes: 'stock/cakes/cakes-flowers-and-icing-pixabay',
    catering: 'stock/catering/catering-buffet-desserts-pixabay',
    decoration: 'initial/decoration/decoration-balloons-pixabay',
    dress: 'stock/venue/venue-wedding-ceremony-stocksnap',
    entertainment: 'initial/entertainment/entertainment-candy-carts-liquorice-pixabay',
    florist: 'stock/florist/florist-musty-pink-and-purple-bouquet-all-the-free-stock',
    group: 'stock/venue/venues-wedding-ceremony-stocksnap', // TODO: define image for groups
    jewellery: 'initial/jewellery/jewellery-Nail-art-kaboompics',
    marquee: 'initial/marquee/marquee-aisle-pixabay',
    menswear: 'initial/menswear/menswear-white-suite-pixabay',
    music: 'initial/music/music-guitarist-picjumbo',
    photo: 'stock/photo/photo-all-legs-pixabay',
    planners: 'initial/planners/planners-book-and-heart-kaboompics',
    stationery: 'initial/stationery/stationery-hyacinth-pixabay',
    transport: 'initial/transport/transport-vintage-wedding-car-splitshire',
    venue: 'stock/venue/venues-wedding-ceremony-stocksnap',
    video: 'initial/video/video-converse-pixabay',
  };

  return map[slug] || '';
}

export function isIE11() {
  return typeof window !== 'undefined'
    ? !(window as any).ActiveXObject && 'ActiveXObject' in window
    : false;
}

export function isIE(v: number) {
  return RegExp(`msie${!isNaN(v) ? `\\s${v}` : ''}`, 'i').test(navigator.userAgent);
}

export function getIcon(type: string) {
  const path = '//res.cloudinary.com/bridebook/image/upload/v1436775400/assets/map_pin_pink';
  const ie = isIE11();
  return type === 'normal'
    ? ie
      ? `${path}p.png`
      : `${path}.svg`
    : ie
    ? `${path}_alphap.png`
    : `${path}_alpha.svg`;
}

export function matchDomains(website: string, email?: string) {
  if (email && email.length) {
    const domain = email.substr(email.lastIndexOf('.'));
    return website.indexOf(domain) >= 0;
  }

  return false;
}

type S3File = {
  filename: string;
};

export const buildS3Path = (type: Slug = 'venue', publicId = '', directory = 'weddingsuppliers') =>
  `${directory}/${type}/${publicId}`;

export const buildPublicId = ({ s3file, ext }: { s3file: S3File; ext: string }) =>
  `${s3file.filename.replace(`.${ext}`, '')}`;

export const buildImgixUrl = ({ path }: Pick<IPhoto, 'path'>) => `${imgixBaseURL}/${path}`;

export const getImgixUrl = (photo?: Partial<IPhoto>) =>
  photo ? `${imgixBaseURL}/${(photo as { path: string }).path}` : undefined;

export const getFeedbackPhoto = (feedback: IFeedbackSerialized | Endorsement | null) =>
  (feedback as IFeedbackSerialized)?.photos?.[0];

export const getImgixMetaInfo = (path: IPhoto['path']) => {
  const imgixURL = buildImgixUrl({ path } as IPhoto);

  return fetch(`${imgixURL}?faces=1&fm=json`)
    .then((data) => data.json())
    .then((data) => ({ imgixURL, data }));
};

export const mapS3File = async ({ s3file }: { s3file: S3File }) => {
  const ext = getExt(s3file.filename) ?? '';
  const public_id = buildPublicId({ s3file, ext });
  const path = s3file.filename;
  const imgixURL = buildImgixUrl({ path });
  const result = await getImgixMetaInfo(path);

  const {
    data,
    data: { PixelHeight, PixelWidth },
  } = result;

  const photo = {
    portrait: PixelHeight >= PixelWidth,
    public_id,
    ext,
    imgixURL,
  };

  Object.defineProperty(photo, 'data', {
    get: () => data,
  });

  return photo;
};

export const toUrlQuery = (
  params: Record<string, Parameters<typeof encodeURIComponent>[0] | undefined>,
  endPercent: boolean = false,
): string => {
  // Remove undefined values
  const definedParams: Record<string, string> = pickBy(isDefined, params);
  const query = Object.keys(definedParams)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(definedParams[key])}`)
    .join('&');
  return query ? `${endPercent ? '&' : '?'}${query}` : '';
};

/**
 * Function `truncate` truncates the string and adds ellipsis if string is
 * longed than specified limit amount
 *
 * @function truncate
 * @returns {String} - returns truncated or original string
 * @param text
 * @param lim
 */
export const truncate = (text: string, lim: number): string =>
  text.length > lim ? `${text.substring(0, lim - 3)}…` : text;

/**
 * Function `lowercaseFirstLetter` lowercases first letter of the string
 * longed than specified limit amount
 *
 * @function lowercaseFirstLetter
 * @returns {String} - returns capitalized word
 * @param s
 */
export const lowercaseFirstLetter = (s: string): string => s.charAt(0).toLowerCase() + s.slice(1);

export const trimString = (str: string): string => (typeof str === 'string' ? str.trim() : '');

export const flipTransition = (Velocity: any, node: Element, revealed: boolean) => {
  const classIn = !revealed ? '.revealed' : '.suppliers-block';
  const classOut = revealed ? '.revealed' : '.suppliers-block';
  const layerIn = node.querySelectorAll(classIn);
  const layerOut = node.querySelectorAll(classOut);
  Velocity(layerIn, 'transition.flipYIn', {
    duration: 250,
    stagger: false,
    drag: false,
  });
  Velocity(layerOut, 'transition.flipYOut', {
    duration: 100,
    stagger: false,
    drag: false,
  });
};

/**
 * Function `decode64` converts base64 string to decoded string
 *
 * @function decode64
 * @returns {Object} - returns decoded string
 * @param str
 * @param replaceChars
 */
export const decode64 = (str: string, replaceChars: boolean = false): string => {
  let base64string = str;
  if (replaceChars) {
    base64string = str.replace(/-/g, '/').replace(/\./g, '=').replace(/_/g, '+');
  }

  return Buffer.from(base64string, 'base64').toString('utf8');
};

/**
 * Clean timestamp data from a single object
 * @param data
 */
// @ts-ignore
export const cleanTimestamps = <T>(data: T): T => omit(['createdAt', 'updatedAt'])(data);

/**
 * Clean timestamp data from an Array-of-Objects or an Object-of-Objects
 * @param data
 */
// @ts-ignore
export const mapCleanTimestamps = <T>(data: T): T => map(omit(['createdAt', 'updatedAt']), data);

export const arePropsEqual = <T extends object>(prevProps: T, nextProps: T) =>
  equals(prevProps, nextProps);

export const toTitleCase = (str: string) => {
  if (typeof str !== 'string') return '';
  const smallWords =
    /^(a|an|upon|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|vs?\.?|via)$/i;
  const rule = (match: string, index: number, title: string) => {
    if (
      index > 0 &&
      index + match.length !== title.length &&
      match.search(smallWords) > -1 &&
      title.charAt(index - 2) !== ':' &&
      (title.charAt(index + match.length) !== '-' || title.charAt(index - 1) === '-') &&
      title.charAt(index - 1).search(/[^\s-]/) < 0
    ) {
      return match.toLowerCase();
    }
    if (match.substr(1).search(/[A-Z]|\../) > -1) {
      return match;
    }
    return match.charAt(0).toUpperCase() + match.substr(1);
  };
  return str && str.replace ? str.replace(/[A-Za-z0-9\u00C0-\u00FF]+[^\s-]*/g, rule) : '';
};

export const getVenueUrl = (supplier: IEnquiryWeddingSummarySupplier) => {
  const name = supplier.name.replace(/\s+/g, '-').toLowerCase();
  const county = supplier.county.replace(/\s+/g, '-').toLowerCase();
  const url = `${env.COUPLESIDE_URL}/${
    supplier.country.toLowerCase() === 'de' ? 'de' : 'uk'
  }/wedding-venues/${name}-${county}`;

  return `${url.toLowerCase()}-${supplier.publicId}`;
};

export const useAppSelector: TypedUseSelectorHook<IApplicationState> = useSelector;
export const useAppStore = useStore as () => Store<IApplicationState>;

/**
 * Returns a hash code from a string
 * @param  {String} str The string to hash.
 * @return {Number}    A 32bit integer
 * @see http://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
 */
export const hashCode = (str: string) => {
  let hash = 0;
  for (let i = 0, len = str.length; i < len; i++) {
    const chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export const debounce = <T extends (...args: any[]) => ReturnType<T>>(
  callback: T,
  timeout: number,
): ((...args: Parameters<T>) => void) => {
  let timer: ReturnType<typeof setTimeout>;

  return (...args: Parameters<T>) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      callback(...args);
    }, timeout);
  };
};

export const noopAction = () => ({ type: 'NOOP' });
