import type { TransitionStatus } from 'react-transition-group/Transition';
import type { TooltipActionType } from 'src/components/bbcommon/tooltip/tooltip';
import { animationTime, colors, spacing } from '../../../themes/variables';
import { FelaCSS } from '../../fela/flowtypes';
import { TOOLTIP_ARROW_SIZE, TOOLTIP_X_OFFSET, TOOLTIP_Y_OFFSET } from './utils/constants';

interface IStyles {
  triggerWrap(type: TooltipActionType): FelaCSS;
  tooltip(props: {
    top?: number;
    left?: number;
    base: string;
    alignment?: string;
    transitionState: TransitionStatus;
  }): FelaCSS;
  background: FelaCSS;
  content: FelaCSS;
  tipText: FelaCSS;
  tipTitle: FelaCSS;
}

interface IStyleProps {
  theme: 'light' | 'dark';
  noPadding: boolean;
  minWidth: FelaCSS['minWidth'];
  maxWidth: FelaCSS['maxWidth'];
  maxHeight: FelaCSS['maxHeight'];
  withArrow: boolean;
}

export const TOOLTIP_BORDER_RADIUS = 16;
export const TOOLTIP_WRAPPER_PADDING = 8;
export const TOOLTIP_ANIMATION_TIME = animationTime.fast;

export const componentStyles = ({
  theme,
  noPadding,
  minWidth,
  maxWidth,
  maxHeight,
  withArrow,
}: IStyleProps): IStyles => ({
  triggerWrap: (type) => ({
    display: 'inline-flex',
    position: 'relative',
    cursor: type === 'click' ? 'pointer' : 'default',

    // For some reason, svg icons trigger onMouseEnter twice, so we need to prevent it
    '& svg': {
      pointerEvents: 'none',
    },
  }),

  tooltip: ({ left, top, base, alignment, transitionState }) => ({
    position: 'absolute',
    top: `${top}px`,
    left: `${left}px`,
    zIndex: 9999999,
    transition: `opacity ${TOOLTIP_ANIMATION_TIME}ms ease-out, transform ${TOOLTIP_ANIMATION_TIME}ms ease-out`,
    willChange: 'opacity, transform',
    boxShadow: `0 5px 10px 0 rgba(0, 0, 0, 0.12)`,
    borderRadius: TOOLTIP_BORDER_RADIUS,

    ...(theme === 'light' && {
      border: `1px solid ${colors.space15}`,
    }),

    ...(transitionState === 'entering' && {
      opacity: 0,
      ...(base === 'bottom' && { transform: 'translateY(-7px)' }),
      ...(base === 'top' && { transform: 'translateY(7px)' }),
      ...(base === 'left' && { transform: 'translateX(7px)' }),
      ...(base === 'right' && { transform: 'translateX(-7px)' }),
    }),

    ...(transitionState === 'entered' && {
      opacity: 1,
    }),

    ...((transitionState === 'exiting' || transitionState === 'exited') && {
      opacity: 0,
    }),

    ':before': {
      display: withArrow ? 'block' : 'none',
      content: '""',
      position: 'absolute',
      width: TOOLTIP_ARROW_SIZE,
      height: TOOLTIP_ARROW_SIZE,
      transform: 'rotate(45deg)',

      ...(theme === 'light'
        ? {
            background: colors.white,
            border: `1px solid ${colors.space15}`,
          }
        : {
            background: colors.space,
          }),

      // Vertical top/bottom
      ...(base === 'bottom' && { top: -(TOOLTIP_ARROW_SIZE / 2) }),
      ...(base === 'top' && { bottom: -(TOOLTIP_ARROW_SIZE / 2) }),
      ...(['top', 'bottom'].includes(base) &&
        !alignment && {
          marginLeft: 'auto',
          marginRight: 'auto',
          left: 0,
          right: 0,
        }),

      // Horizontal left/right
      ...(base === 'left' && { right: -(TOOLTIP_ARROW_SIZE / 2) }),
      ...(base === 'right' && { left: -(TOOLTIP_ARROW_SIZE / 2) }),
      ...(['left', 'right'].includes(base) &&
        !alignment && {
          top: `calc(50% - ${TOOLTIP_ARROW_SIZE / 2}px)`,
        }),
      ...(alignment === 'top' && {
        bottom: TOOLTIP_Y_OFFSET + TOOLTIP_ARROW_SIZE,
      }),
      ...(alignment === 'bottom' && {
        top: TOOLTIP_Y_OFFSET + TOOLTIP_ARROW_SIZE,
      }),

      // Vertical top/bottom but with horizontally aligned to left/right
      ...(alignment === 'left' && {
        right: TOOLTIP_X_OFFSET - TOOLTIP_ARROW_SIZE / 2,
      }),
      ...(alignment === 'right' && {
        left: TOOLTIP_X_OFFSET - TOOLTIP_ARROW_SIZE / 2,
      }),
    },
  }),

  background: {
    padding: `${TOOLTIP_WRAPPER_PADDING}px`,
    ...(theme === 'light'
      ? {
          // Minus 1px radius to take container border into account
          borderRadius: TOOLTIP_BORDER_RADIUS - 1,
          background: colors.white,
          color: colors.space,
        }
      : {
          borderRadius: TOOLTIP_BORDER_RADIUS,
          background: colors.space,
          color: colors.white,
        }),
  },

  content: {
    fontDefault: 14,
    textAlign: 'left',
    gap: spacing[2],
    ...(!noPadding && { padding: 2 }),
    thinScrollbar: true,
    overflowY: 'auto',
    minWidth,
    maxWidth,
    maxHeight,
    width: 'fit-content',
  },

  tipTitle: {
    fontDefaultSemiBold: 16,
  },

  tipText: {
    fontDefault: 14,
    lineHeight: 1.4,
  },
});
