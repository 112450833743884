/**
 * Find the scrollable parent of the given node
 */
export const findScrollParent = (node: HTMLElement): HTMLElement => {
  if (!node || node === document.documentElement) {
    return document.documentElement;
  }

  // Check if node is an Element before getting computed style
  if (node instanceof HTMLElement) {
    const style = window.getComputedStyle(node);
    const overflow = style.getPropertyValue('overflow');
    const overflowY = style.getPropertyValue('overflow-y');
    const isScrollable =
      overflow === 'auto' ||
      overflow === 'scroll' ||
      overflowY === 'auto' ||
      overflowY === 'scroll';

    if (isScrollable && node.scrollHeight > node.clientHeight) {
      return node;
    }
  }

  // Ensure we're getting a valid parent node
  const parentNode = node.parentElement || document.documentElement;
  return findScrollParent(parentNode);
};

/**
 * Check if the device supports hover events.
 */
export const supportsHover = () => {
  const mediaQuery = typeof window !== 'undefined' && window.matchMedia('(hover: hover)');
  return mediaQuery.matches;
};
