import { setSortReviewsMethod, setSortTestimonialsMethod } from 'lib/feedback/actions/misc';
import { addFeedbackPhoto, setFeedbackPhoto } from './actions/add-feedback-photo';
import { deleteFeedback } from './actions/delete-feedback';
import { editFeedback, setFeedbackField, setFeedbackVerified } from './actions/edit-feedback';
import { saveFeedback, saveReviewResponse } from './actions/save-feedback';
import { updateFeedback } from './actions/update-feedback';

export enum FeedbackActions {
  INIT_FEEDBACK_LISTENER = '[Feedback] Initialise feedback listener',
  STOP_FEEDBACK_LISTENER = '[Feedback] Stop feedback listener',
  UPDATE_FEEDBACK_LIST = '[Feedback] Update feedback',
  EDIT_FEEDBACK = '[Feedback] Edit feedback',
  SET_FEEDBACK_FIELD = '[Feedback] Set feedback field',
  SET_FEEDBACK_VERIFIED = '[Feedback] Set feedback verified',
  SAVE_FEEDBACK = '[Feedback] Save feedback',
  SAVE_FEEDBACK_SUCCESS = '[Feedback] Save feedback success',
  DELETE_FEEDBACK = '[Feedback] Delete feedback',
  DELETE_FEEDBACK_SUCCESS = '[Feedback] Delete feedback success',
  DELETE_FEEDBACK_ERROR = '[Feedback] Delete feedback error',
  ADD_FEEDBACK_PHOTO = '[Feedback] Add feedback photo',
  ADD_FEEDBACK_PHOTO_ERROR = '[Feedback] Add feedback photo error',
  SET_FEEDBACK_PHOTO = '[Feedback] Set feedback photo',
  SET_SORT_REVIEWS_METHOD = '[Feedback] Set sort reviews method',
  SET_SORT_TESTIMONIALS_METHOD = '[Feedback] Set sort testimonials method',
  SAVE_REVIEW_RESPONSE = '[Feedback] Save review response',
  CLEAR_MODAL_VALUES = '[Feedback] Clear modal',
  SET_EDITING = '[Feedback] Set editing',
}

export type UpdateFeedbackAction = ReturnType<typeof updateFeedback>;

export type SaveFeedbackAction = ReturnType<typeof saveFeedback>;

export type DeleteFeedbackAction = ReturnType<typeof deleteFeedback>;

export type EditFeedbackAction = ReturnType<typeof editFeedback>;

export type SetFeedbackFieldAction = ReturnType<typeof setFeedbackField>;

export type SetFeedbackVerifiedAction = ReturnType<typeof setFeedbackVerified>;

export type AddFeedbackPhotoAction = ReturnType<typeof addFeedbackPhoto>;

export type SetFeedbackPhotoAction = ReturnType<typeof setFeedbackPhoto>;

export type SetSortReviewsMethodAction = ReturnType<typeof setSortReviewsMethod>;

export type SetSortTestimonialsMethodAction = ReturnType<typeof setSortTestimonialsMethod>;

export type SaveReviewResponseAction = ReturnType<typeof saveReviewResponse>;
