import Router from 'next/router';
import { pathOr } from 'ramda';
import { SentryMinimal } from '@bridebook/toolbox/src/sentry';
import { switchSupplier } from 'lib/access-control/actions';
import { AuthActionTypes } from 'lib/auth/action-types';
import { IDeps } from 'lib/types';
import { UrlHelper } from 'lib/url-helper';
import { onUserListener } from 'lib/users/actions/user-listener';
import { getUsersViewer } from 'lib/users/selectors';

export const signOut =
  ({ nextRoute }: { nextRoute?: string } = {}) =>
  ({ dispatch, getState, firebaseAuth }: IDeps) => {
    const email = pathOr('', ['contacts', 'email'], getUsersViewer(getState()));
    dispatch({ type: 'LOGOUT_ANALYTICS', payload: email });

    firebaseAuth().signOut();
    /** Reset Sentry context */
    SentryMinimal().setUser(null);

    return {
      type: AuthActionTypes.SIGN_OUT,
      payload: { nextRoute },
    };
  };

export const signOutSuccess =
  () =>
  ({ dispatch, getState }: IDeps) => {
    dispatch(onUserListener(null));
    dispatch(switchSupplier({ activeSupplierAccessControl: null }));

    const nextRoute = getState().auth.nextRoute;
    const url = nextRoute ? `${UrlHelper.auth.login}?next=${nextRoute}` : UrlHelper.auth.login;
    Router.push(url);

    return {
      type: AuthActionTypes.SIGN_OUT_SUCCESS,
    };
  };
