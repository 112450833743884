import { IFeedback } from '@bridebook/models/source/models/Suppliers/Feedback.types';
import { TTimestampedSerialized } from '@bridebook/toolbox/src/serialize-timestamps';
import { Photo } from 'lib/photos/types';

export interface IFeedbackSerialized
  extends Omit<IFeedback, 'createdAt' | 'updatedAt' | 'dateWedding' | 'id' | 'response'> {
  createdAt: number;
  updatedAt?: number;
  dateWedding?: number | string;
  id: string;
  response?: TTimestampedSerialized<Required<IFeedback>['response']>;
}

export enum SortReviewsOptions {
  mostRecent = 'mostRecent',
  oldest = 'oldest',
  lowToHigh = 'lowToHigh',
  highToLow = 'highToLow',
}

export enum SortTestimonialsOptions {
  mostRecent = 'mostRecent',
  oldest = 'oldest',
}

export interface Review {
  createdAt: number;
  email: string;
  from: string;
  id: string;
  message: string;
  name: string;
  photo?: Photo;
  stars: string;
  title: string;
  weddingDate: string;
}
