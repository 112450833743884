import { original, produce } from 'immer';
import { AccessControlActionTypes } from 'lib/access-control/action-types';
import { Action, IReducersImmer } from '../types';
import {
  EnquiriesActionTypes,
  IEditEnquiryInfoSuccessAction,
  IFetchEnquirySuccess,
  ISetEnquiriesOwner,
  ISetEnquiriesPage,
  ISetEnquiriesStatus,
  ISetEnquiryProgress,
  ISetEnquiryStatus,
  ISetOwnerFilter,
  IUpdateEnquiryOwner,
  IUpdateEnquiryScheduledFollowUp,
  IUpdateEnquiryWidgetSettings,
  IUpdateNotesCountSuccess,
} from './action-types';
import { enquiryWidgetSettingsDefault } from './enquiry-widget-settings-default';
import { IEnquiriesState } from './types';

const initialState: IEnquiriesState = {
  enquiry: null,
  weddingSummary: null,
  nextEnquiryId: null,
  status: 'inprogress',
  ownerFilter: undefined,
  page: 0,
  enquiryWidgetSettings: enquiryWidgetSettingsDefault,
  csvExportInProgress: false,
};

const updateProgress = (draft: IEnquiriesState, action: any) => {
  const { newProgress } = action.payload || {};
  const state = original(draft);
  if (!state) return;

  draft.enquiry = state.enquiry ? { ...state.enquiry, progress: newProgress } : null;
};

const reducers: IReducersImmer<IEnquiriesState> = (draft: IEnquiriesState) => ({
  [EnquiriesActionTypes.FETCH_ENQUIRY_START]: () => {
    draft.enquiry = null;
    draft.nextEnquiryId = null;
  },

  [EnquiriesActionTypes.FETCH_ENQUIRY_SUCCESS]: (action: IFetchEnquirySuccess) => {
    const { supplierWedding, next, weddingSummary } = action.payload || {};
    if (!supplierWedding) return;
    draft.enquiry = supplierWedding;
    draft.nextEnquiryId = next || null;
    draft.weddingSummary = weddingSummary || null;
  },

  [EnquiriesActionTypes.UPDATE_NOTES_COUNT_SUCCESS]: (action: IUpdateNotesCountSuccess) => {
    if (!draft.enquiry) return;
    draft.enquiry.notesCount = action.payload;
  },

  [EnquiriesActionTypes.SET_ENQUIRY_STATUS]: (action: ISetEnquiryStatus) => {
    const { status, value } = action.payload || {};
    const state = original(draft);
    if (!state) return;

    draft.enquiry = state.enquiry
      ? { ...state.enquiry, flags: { ...state.enquiry.flags, [status]: value } }
      : null;
  },

  [EnquiriesActionTypes.SET_ENQUIRY_PROGRESS]: (action: ISetEnquiryProgress) => {
    updateProgress(draft, action);
  },

  [EnquiriesActionTypes.SET_ENQUIRY_PROGRESS_SUCCESS]: (action: ISetEnquiryProgress) => {
    updateProgress(draft, action);
  },

  [EnquiriesActionTypes.UPDATE_OWNER]: (action: IUpdateEnquiryOwner) => {
    const { ownerId } = action.payload;
    const state = original(draft);
    if (!state) return;

    const enquiry = state.enquiry ? { ...state.enquiry, owner: ownerId } : null;
    draft.enquiry = enquiry;
  },

  [EnquiriesActionTypes.SET_OWNER_FILTER]: (action: ISetOwnerFilter) => {
    const { ownerFilter } = action.payload;
    draft.ownerFilter = ownerFilter;
  },

  [AccessControlActionTypes.SWITCH_SUPPLIER]: () => initialState,

  [AccessControlActionTypes.SWITCH_PERMISSION_START]: () => initialState,

  [EnquiriesActionTypes.UPDATE_ENQUIRY_WIDGET_SETTINGS]: (action: IUpdateEnquiryWidgetSettings) => {
    const { name, value } = action.payload;
    draft.enquiryWidgetSettings[name] = value;
  },

  [EnquiriesActionTypes.EDIT_ENQUIRY_INFO_SUCCESS]: (action: IEditEnquiryInfoSuccessAction) => {
    draft.enquiry = action.payload;
  },

  [EnquiriesActionTypes.UPDATE_ENQUIRY_SCHEDULED_FOLLOW_UP]: (
    action: IUpdateEnquiryScheduledFollowUp,
  ) => {
    const state = original(draft);

    if (!state) return;

    draft.enquiry = state.enquiry ? { ...state.enquiry, ...action.payload } : null;
  },

  [EnquiriesActionTypes.SET_ENQUIRIES_PAGE]: (action: ISetEnquiriesPage) => {
    draft.page = action.payload;
  },

  [EnquiriesActionTypes.SET_ENQUIRIES_STATUS]: (action: ISetEnquiriesStatus) => {
    draft.status = action.payload;
  },

  [EnquiriesActionTypes.SET_ENQUIRIES_OWNER]: (action: ISetEnquiriesOwner) => {
    draft.ownerFilter = action.payload;
  },

  [EnquiriesActionTypes.EXPORT_ENQUIRIES_CSV]: () => {
    draft.csvExportInProgress = true;
  },

  [EnquiriesActionTypes.CSV_EXPORT_SUCCESS]: () => {
    draft.csvExportInProgress = false;
  },
});

const reducer = (state: IEnquiriesState = initialState, action: Action): IEnquiriesState => {
  try {
    return produce(state, (draft) => reducers(draft)[action.type](action));
  } catch (err) {
    return state;
  }
};

export default reducer;
