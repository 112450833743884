import { createSlice } from '@reduxjs/toolkit';

export type MenuState = {
  sidebar: {
    maximised: boolean;
  };
  topbar: {
    show: boolean;
    showProfile: boolean;
  };
};

const initialState: MenuState = {
  sidebar: {
    maximised: false,
  },
  topbar: {
    show: false,
    showProfile: false,
  },
};

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    expandSidebar(state) {
      state.sidebar.maximised = true;
    },
    collapseSidebar(state) {
      state.sidebar.maximised = false;
    },
    toggleSidebar(state) {
      state.sidebar.maximised = !state.sidebar.maximised;
    },
    toggleTopbar(state) {
      state.topbar.show = !state.topbar.show;
    },
    toggleTopbarProfile(state) {
      state.topbar.showProfile = !state.topbar.showProfile;
    },
  },
});

export const { toggleTopbar, toggleTopbarProfile, collapseSidebar, expandSidebar, toggleSidebar } =
  menuSlice.actions;
export default menuSlice.reducer;
