import { IS3File } from '@bridebook/ui';
import { FeedbackActions } from '../action-types';

export const addFeedbackPhoto = (s3file: IS3File) => ({
  type: FeedbackActions.ADD_FEEDBACK_PHOTO,
  payload: { s3file },
});

export const addFeedbackPhotoError = (error: Error) => ({
  type: FeedbackActions.ADD_FEEDBACK_PHOTO_ERROR,
  payload: { error },
});

export const setFeedbackPhoto = (photo: { path: string; portrait?: boolean }) => ({
  type: FeedbackActions.SET_FEEDBACK_PHOTO,
  payload: { photo },
});
