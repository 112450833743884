import React, {
  FC,
  MouseEventHandler,
  MouseEvent as MouseEventType,
  PropsWithChildren,
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { Transition } from 'react-transition-group';
import { Box, type FelaCSS, type TColor } from '@bridebook/ui';
import { IconInfoCircle } from '@bridebook/ui/src/icons/dynamic';
import { TOOLTIP_ANIMATION_TIME, componentStyles } from './tooltip.style';
import {
  TOOLTIP_ARROW_SIZE,
  TOOLTIP_CALLBACK_DELAY,
  TOOLTIP_X_OFFSET,
  TOOLTIP_Y_OFFSET,
} from './utils/constants';
import { findScrollParent, supportsHover } from './utils/helpers';

type TooltipOrientation =
  | 'top'
  | 'top-left'
  | 'top-right'
  | 'bottom'
  | 'bottom-left'
  | 'bottom-right'
  | 'left'
  | 'left-top'
  | 'left-bottom'
  | 'right'
  | 'right-top'
  | 'right-bottom';

export type TooltipActionType = 'click' | 'hover';

interface IProps {
  /**
   * Sets position relative to content.
   */
  orientation?: TooltipOrientation;
  /**
   * Set minimum width of the tooltip.
   */
  minWidth?: FelaCSS['minWidth'];
  /**
   * Maximum width of the tooltip. The width is adjusted to the content up to this value.
   */
  maxWidth?: FelaCSS['maxWidth'];
  /**
   * Maximum height of the tooltip. The height is adjusted to the content up to this value.
   */
  maxHeight?: FelaCSS['maxHeight'];
  /**
   * Callback function to handle tooltip show/hide event. Receives false/true argument.
   */
  onToggle?: (visible: boolean, type: TooltipActionType) => void;
  /**
   * Sets tooltip interaction type.
   */
  type?: TooltipActionType;
  /**
   * Custom trigger element. If not set, default trigger will be used.
   */
  renderTrigger?: (isOpened: boolean) => ReactNode;
  /**
   * Size of the default trigger icon.
   */
  triggerSize?: number;
  /**
   * Color of the default trigger icon.
   */
  triggerColor?: TColor;
  /**
   * Tooltip title or text with a bigger font size.
   */
  title?: string;
  /**
   * Smaller text content
   */
  text?: string;
  /**
   * Light or dark color of the tooltip
   */
  theme?: 'light' | 'dark';
  /**
   * Removes inner padding
   */
  noPadding?: boolean;
  /**
   * Show tooltip arrow
   */
  withArrow?: boolean;
  /**
   * Delay before the tooltip is shown
   */
  delay?: number;
}

export const closeAllTooltips = () =>
  document.body.dispatchEvent(
    new MouseEvent('mousedown', {
      bubbles: true,
      cancelable: true,
    }),
  );

const TooltipComp: FC<PropsWithChildren<IProps>> = ({
  orientation = 'top',
  minWidth = 20,
  maxWidth = 300,
  maxHeight = 400,
  noPadding = false,
  withArrow = true,
  type: _type = 'hover',
  triggerSize = 18,
  triggerColor = 'indigoCrush',
  title,
  text,
  theme = 'dark',
  onToggle: onToggleCb,
  children,
  renderTrigger,
  delay = 50,
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const tooltipTriggerRef = useRef<HTMLDivElement>(null);
  const [tooltipEl, setTooltipEl] = useState<HTMLDivElement>();
  const container = useRef<HTMLElement | undefined>();
  const hoverTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const setTooltipRefCallback = useCallback((node: HTMLDivElement) => {
    setTooltipEl(node);
  }, []);

  // Check if the device supports hover events, otherwise use click (eg. mobile)
  const type = _type === 'hover' && supportsHover() ? 'hover' : 'click';

  /**
   * Event handler to open the tooltip
   */
  const onOpen = useCallback(
    (e?: Pick<MouseEventType, 'stopPropagation'>) => {
      e?.stopPropagation();
      setIsOpened(true);

      // Add a minimum hover time before triggering the callback
      if (type === 'hover') {
        if (hoverTimeoutRef.current) return; // Prevent multiple timeouts
        hoverTimeoutRef.current = setTimeout(() => {
          onToggleCb?.(true, type);
          hoverTimeoutRef.current = null;
        }, TOOLTIP_CALLBACK_DELAY);
      } else {
        onToggleCb?.(true, type);
      }
    },
    [onToggleCb, type],
  );

  /**
   * Event handler to close the tooltip
   */
  const onClose = useCallback(
    (e?: Pick<MouseEventType, 'stopPropagation'>) => {
      e?.stopPropagation();
      setIsOpened(false);

      // If timeout still exists, it means the tooltip was opened and closed quickly
      if (hoverTimeoutRef.current) {
        clearTimeout(hoverTimeoutRef.current);
        hoverTimeoutRef.current = null;
      } else {
        // Trigger callback only if the tooltip was opened for a required time
        onToggleCb?.(false, type);
      }
    },
    [onToggleCb, type],
  );

  /**
   * Toggles open/close state
   */
  const onToggle = useCallback(
    () => (isOpened ? onClose() : onOpen()),
    [isOpened, onClose, onOpen],
  );

  /**
   *
   */
  const onClick: MouseEventHandler<ReactNode> = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      onToggle();
    },
    [onToggle],
  );

  /**
   * Find the scrollable container for the tooltip on the first isActive change.
   * We don't want to do this on mount, because it's an expensive operation.
   */
  useEffect(() => {
    if (isOpened && !container.current) {
      container.current = findScrollParent(tooltipTriggerRef.current);
    }
  }, [isOpened]);

  /**
   * Calculates tooltip position based on orientation and trigger position.
   */
  const tooltipPosition = useMemo(() => {
    const triggerRect = tooltipTriggerRef.current?.getBoundingClientRect();
    const tooltipRect = tooltipEl?.getBoundingClientRect();

    if (!triggerRect || !tooltipRect) {
      return { base: 'top' };
    }

    const scrollY = document.documentElement.scrollTop;

    const horizontalOffset = withArrow ? triggerRect.width / 2 - TOOLTIP_X_OFFSET : 0;
    const verticalOffset = withArrow ? TOOLTIP_Y_OFFSET + TOOLTIP_ARROW_SIZE / 2 : 0;

    // Calculate common alignments
    const horizontalCenter = triggerRect.left + triggerRect.width / 2;
    const verticalCenter = triggerRect.top + triggerRect.height / 2;

    // Pre-calculate all common positions
    const basePositions = {
      // Horizontal positions
      xCenter: horizontalCenter - tooltipRect.width / 2,
      xCenterRight: triggerRect.left + horizontalOffset,
      xCenterLeft: triggerRect.right - tooltipRect.width - horizontalOffset,
      xLeft: triggerRect.left - tooltipRect.width - TOOLTIP_ARROW_SIZE,
      xRight: triggerRect.right + TOOLTIP_ARROW_SIZE,

      // Vertical positions
      yCenter: verticalCenter - tooltipRect.height / 2 + scrollY,
      yCenterDown: triggerRect.top - verticalOffset + scrollY,
      yCenterUp: triggerRect.bottom - tooltipRect.height + verticalOffset + scrollY,
      yAbove: triggerRect.top - tooltipRect.height - TOOLTIP_ARROW_SIZE + scrollY,
      yBelow: triggerRect.bottom + TOOLTIP_ARROW_SIZE + scrollY,
    };

    const positionCalculators: Record<TooltipOrientation, () => { top: number; left: number }> = {
      bottom: () => ({
        top: basePositions.yBelow,
        left: basePositions.xCenter,
      }),
      'bottom-right': () => ({
        top: basePositions.yBelow,
        left: basePositions.xCenterRight,
      }),
      'bottom-left': () => ({
        top: basePositions.yBelow,
        left: basePositions.xCenterLeft,
      }),
      top: () => ({
        top: basePositions.yAbove,
        left: basePositions.xCenter,
      }),
      'top-right': () => ({
        top: basePositions.yAbove,
        left: basePositions.xCenterRight,
      }),
      'top-left': () => ({
        top: basePositions.yAbove,
        left: basePositions.xCenterLeft,
      }),
      left: () => ({
        top: basePositions.yCenter,
        left: basePositions.xLeft,
      }),
      'left-top': () => ({
        top: basePositions.yCenterUp,
        left: basePositions.xLeft,
      }),
      'left-bottom': () => ({
        top: basePositions.yCenterDown,
        left: basePositions.xLeft,
      }),
      right: () => ({
        top: basePositions.yCenter,
        left: basePositions.xRight,
      }),
      'right-top': () => ({
        top: basePositions.yCenterUp,
        left: basePositions.xRight,
      }),
      'right-bottom': () => ({
        top: basePositions.yCenterDown,
        left: basePositions.xRight,
      }),
    };

    // Check if the tooltip fits in the viewport, otherwise adjust the orientation
    const originalPosition = positionCalculators[orientation]();
    const [originalBase, originalAlignment] = orientation.split('-');
    const extendsTop = originalPosition.top < 0;
    const extendsBottom = originalPosition.top + tooltipRect.height > window.innerHeight;
    const extendsLeft = originalPosition.left < 0;
    const extendsRight = originalPosition.left + tooltipRect.width > window.innerWidth;

    const base = (() => {
      // Check top/bottom tooltips
      if (['top', 'bottom'].includes(originalBase)) {
        if (extendsTop) return 'bottom';
        if (extendsBottom) return 'top';
      }
      // Check left/right tooltips
      else if (['left', 'right'].includes(originalBase)) {
        if (extendsLeft) return 'right';
        if (extendsRight) return 'left';
      }
      return originalBase;
    })();

    const alignment = (() => {
      // Check top/bottom tooltips
      if (['top', 'bottom'].includes(originalBase)) {
        if (extendsLeft) return 'right';
        if (extendsRight) return 'left';
      }
      // Check left/right tooltips
      else if (['left', 'right'].includes(originalBase)) {
        if (extendsTop) return 'bottom';
        if (extendsBottom) return 'top';
      }
      return originalAlignment;
    })();

    const adjustedOrientation = [base, alignment].filter(Boolean).join('-') as TooltipOrientation;
    if (adjustedOrientation === orientation) {
      return { ...originalPosition, base, alignment };
    }

    const adjustedPosition = positionCalculators[adjustedOrientation]();
    return {
      top: Math.max(adjustedPosition.top, 0),
      left: Math.max(adjustedPosition.left, 0),
      base,
      alignment,
    };
  }, [orientation, tooltipEl, withArrow]);

  /**
   * Close tooltip on:
   * - outside click
   * - window resize
   * - container scroll
   */
  useEffect(() => {
    const handleOutsideClick: EventListener = (event) => {
      if (
        !tooltipEl.contains(event.target as Node) &&
        !tooltipTriggerRef.current?.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    if (tooltipEl) {
      document.addEventListener('mousedown', handleOutsideClick);
      document.addEventListener('touchstart', handleOutsideClick);
      window.addEventListener('resize', onClose);
      container.current?.addEventListener('scroll', onClose);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('touchstart', handleOutsideClick);
      window.removeEventListener('resize', onClose);
      container.current?.removeEventListener('scroll', onClose);
    };
  }, [onClose, tooltipEl, type]);

  /**
   * Callbacks for different trigger types
   */
  const interactionHandlers = useMemo(
    () =>
      type === 'click'
        ? { onClick }
        : {
            onMouseEnter: onOpen,
            onMouseLeave: onClose,
          },
    [type, onClick, onOpen, onClose],
  );

  // Memoize trigger component
  const TriggerComp = useMemo(
    () => renderTrigger?.(isOpened) || <IconInfoCircle width={triggerSize} color={triggerColor} />,
    [isOpened, renderTrigger, triggerColor, triggerSize],
  );

  const styles = componentStyles({ theme, noPadding, minWidth, maxWidth, maxHeight, withArrow });

  return (
    <>
      <Box style={styles.triggerWrap(type)} {...interactionHandlers} setRef={tooltipTriggerRef}>
        {TriggerComp}
      </Box>
      <Transition
        in={isOpened}
        timeout={{ enter: delay, exit: TOOLTIP_ANIMATION_TIME }}
        enter
        exit
        appear
        mountOnEnter
        unmountOnExit>
        {(transitionState) =>
          createPortal(
            <Box
              data-name="tooltip-wrapper"
              setRef={setTooltipRefCallback}
              style={styles.tooltip({
                ...tooltipPosition,
                transitionState,
              })}>
              <Box style={styles.background} data-name="tooltip-background">
                <Box style={styles.content} data-name="tooltip-content">
                  {title && (
                    <Box data-name="tooltip-title" style={styles.tipTitle}>
                      {title}
                    </Box>
                  )}
                  {text && (
                    <Box data-name="tooltip-text" style={styles.tipText}>
                      {text}
                    </Box>
                  )}
                  {children}
                </Box>
              </Box>
            </Box>,
            document.body,
          )
        }
      </Transition>
    </>
  );
};

export const Tooltip = memo(TooltipComp);
