import { FelaCSS, colors, spacing } from '@bridebook/ui';

interface IStyles {
  wrapper: FelaCSS;
  label: FelaCSS;
  valueWrap: FelaCSS;
  value: FelaCSS;
  chevronWrap: (isOpened: boolean) => FelaCSS;
}

const styles = (): IStyles => ({
  wrapper: {
    cursor: 'pointer',
  },

  label: {
    color: colors.space60,
    fontDefault: 12,
  },

  valueWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: spacing[2],
  },

  value: {
    color: colors.space,
    fontDefault: 14,
    lineHeight: '16px',
  },

  chevronWrap: (isOpened) => ({
    transform: isOpened ? 'rotate(180deg)' : 'none',
  }),
});

export default styles;
