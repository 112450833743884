import React, { memo } from 'react';
import { getI18n, useTranslation } from 'react-i18next';
import {
  PopoverMenu,
  PopoverMenuHeader,
  PopoverMenuItem,
  PopoverMenuLabeledTrigger,
} from '@bridebook/ui';
import CountryFlag from 'components/bbcommon/country-flag/country-flag';
import { getLocaleNames } from 'lib/i18n/data/locale-names';
import { useAppSelector } from 'lib/utils';

const CountrySelectComponent = () => {
  const { t } = useTranslation('loggedOut');
  const language = useAppSelector((state) => state.i18n.language);

  const closeTooltip = () => document.body.click();

  const setLocale = (language: string) => () => {
    getI18n().changeLanguage(language);
    closeTooltip();
  };

  const localeNames = getLocaleNames();
  const selectedIcon = (
    <CountryFlag
      locale={localeNames.find(({ locales }) => locales[0] === language)?.locales[0]}
      size={25}
    />
  );

  return (
    <PopoverMenu
      renderTrigger={(isOpened) => (
        <PopoverMenuLabeledTrigger isOpened={isOpened} iconColor="white">
          {selectedIcon}
        </PopoverMenuLabeledTrigger>
      )}>
      <PopoverMenuHeader>{t('selectLanguage')}</PopoverMenuHeader>
      {...localeNames.map(({ name, locales }) => (
        <PopoverMenuItem
          key={locales[0]}
          icon={<CountryFlag locale={locales[0]} size={25} />}
          isSelected={locales.includes(language)}
          onClick={setLocale(locales[0])}>
          {name}
        </PopoverMenuItem>
      ))}
    </PopoverMenu>
  );
};

export const CountrySelect = memo(CountrySelectComponent);
