import React, { FC, PropsWithChildren } from 'react';
import { Box } from '@bridebook/ui';
import { styles } from '../popover-menu.style';

/**
 * Header for the popover menu group, not clickable.
 */
export const PopoverMenuHeader: FC<PropsWithChildren> = ({ children }) => (
  <Box style={styles.header} data-name="popover-menu-header">
    {children}
  </Box>
);
