import { WithFieldValue } from 'firebase/firestore';
import { ISupplierAdmin } from '@bridebook/models/source/models/Suppliers/Admin.types';
import type { PremiumTiers } from '@bridebook/models/source/models/Suppliers/Admin/admin.types';
import { AddonAvailabilityStatus } from 'lib/api/chargebee/addons/utils/availability-status';
import { ChargebeeAddonTypes, UIBillingPeriod } from 'lib/api/chargebee/types';
import { PremiumAnalyticsActionTypes } from 'lib/premium/analytics/action-types';
import { mapChargebeeAddonTypeToAnalytics } from 'lib/premium/analytics/utils';
import { VenueProContactFormCompletedStorage } from 'lib/storage-manager/basic/venuepro-contact-form-storage';
import { TTouchPointLocation } from 'lib/types';

export const hubspotCompletedAnalytics = () => {
  VenueProContactFormCompletedStorage.set(true);
  return {
    type: PremiumAnalyticsActionTypes.HUBSPOT_COMPLETED,
  };
};

export const updatedSupplierPremiumStatusAnalytics = ({
  premiumUpdateLocation,
  premium,
}: {
  premiumUpdateLocation: 'admin panel' | 'trialExpirationPopup' | 'checkout';
  premium: WithFieldValue<ISupplierAdmin['premium']>;
}) => ({
  type: PremiumAnalyticsActionTypes.UPDATED_SUPPLIER_PREMIUM_STATUS,
  payload: { premiumUpdateLocation, premium },
});

export const checkedAddonAvailabilityAnalytics = (payload: {
  selectedAddonAvailability: boolean;
  selectedAddonAvailabilityStatus?: AddonAvailabilityStatus;
  selectedAddonType?: ChargebeeAddonTypes;
  selectedCounty?: string;
}) => ({
  type: PremiumAnalyticsActionTypes.CHECKED_ADDON_AVAILABILITY,
  payload: {
    ...payload,
    ...(payload.selectedAddonType && {
      selectedAddonType: mapChargebeeAddonTypeToAnalytics[payload.selectedAddonType],
    }),
    interactionLocation: 'upgradesUpsellPage',
  },
});

export const interactedWithSelfServeAnalytics = (payload: {
  interactionType:
    | 'upgradeTier'
    | 'comingSoon'
    | 'requestMeetingQuestions'
    | 'requestMeetingPrePurchase'
    | 'requestMeetingPostPurchase'
    | 'requestMeetingUpgrade'
    | 'requestMeetingDowngrade'
    | 'joinWaitlist'
    | 'billingPeriod'
    | 'buyNow'
    | 'supportQuestions';
  interactionLocation: 'tierUpsellPage' | 'upgradesUpsellPage';
  selectedTier?: PremiumTiers;
  selectedBillingPeriod?: UIBillingPeriod;
  selectedAddonType?: ChargebeeAddonTypes;
  selectedCounty?: string;
}) => ({
  type: PremiumAnalyticsActionTypes.INTERACTED_WITH_SELF_SERVE,
  payload: {
    ...payload,
    ...(payload.selectedAddonType && {
      selectedAddonType: mapChargebeeAddonTypeToAnalytics[payload.selectedAddonType],
    }),
  },
});

export const interactedWithCheckoutAnalytics = (payload: {
  interactionType: 'opened' | 'closed' | 'nextStep' | 'success' | 'error';
  interactionLocation: 'tierUpsellPage' | 'upgradesUpsellPage' | 'standalone' | 'acceptQuote';
  selectedPriceId?: string;
  selectedTier?: PremiumTiers;
  selectedBillingPeriod?: UIBillingPeriod;
  selectedAddonType?: ChargebeeAddonTypes;
  selectedCounty?: string;
  stepId?: string;
  errorMessage?: string;
  // Can override supplierId from state - when using redirectUrl from external Chargebee (eg. accept a quote).
  // It could happen that a logged-in supplier in state is different than the one from checkout.
  // Ideally, we should require logged-in supplier to match the one from checkout.
  supplierId?: string;
}) => ({
  type: PremiumAnalyticsActionTypes.INTERACTED_WITH_CHECKOUT,
  payload: {
    ...payload,
    ...(payload.selectedAddonType && {
      selectedAddonType: mapChargebeeAddonTypeToAnalytics[payload.selectedAddonType],
    }),
  },
});

export const clickedToViewUpgradeOptionsAnalytics = (
  payload: {
    touchPointLocation?: TTouchPointLocation;
    selectedTier?: PremiumTiers;
    selectedAddonType?: ChargebeeAddonTypes;
  },
  upgradeOptionDestination: 'tierUpsellPage' | 'marketingPage',
) => ({
  type: PremiumAnalyticsActionTypes.CLICKED_TO_VIEW_UPGRADE_OPTIONS,
  payload: {
    ...payload,
    upgradeOptionDestination,
  },
});
