import { User as FirebaseUser } from 'firebase/auth';
import { AuthActionTypes } from 'lib/auth/action-types';
import { redirectAfterAuth } from 'lib/auth/actions';
import { IDeps } from 'lib/types';
import createUserFirebase from 'lib/users/utils/create-user-firebase';

export const onAuth = (firebaseUser: FirebaseUser | null) => () => {
  const user = createUserFirebase(firebaseUser);

  return {
    type: AuthActionTypes.ON_AUTH,
    payload: { user, firebaseUser },
  };
};

export const saveTokenDone =
  (response: FirebaseUser | null) =>
  ({ dispatch }: IDeps) => {
    const userId = (response && response.uid) || null;
    if (userId) dispatch(redirectAfterAuth(userId));
    return {
      type: 'SAVE_TOKEN_DONE',
    };
  };

export const clearAuthFormError = () => ({
  type: AuthActionTypes.SIGN_IN_ERROR,
  payload: { error: null },
});
