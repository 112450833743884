/**
 *
 * Te primary purpose of this function is to return a clickable link
 * which can be used for downloading artifacts within Browser context,
 * e.g. when exporting a file. The created link can either be appended to
 * some parent element or directly triggered via `link.click()`.
 *
 * @param {Blob} content
 * @param {string} filename
 *
 * @returns {HTMLAnchorElement}
 */
export function getDownloadableLink(content: Blob, filename: string): HTMLAnchorElement {
  if (typeof document === undefined || typeof window === 'undefined') {
    throw Error('This function must be called in Browser context');
  }

  if (!filename || !content) {
    throw Error('This function must be called in with content and filename');
  }

  const link = document.createElement('a');
  link.download = filename;

  if (window.webkitURL) {
    link.href = window.webkitURL.createObjectURL(content);
  } else {
    link.href = window.URL.createObjectURL(content);
  }

  return link;
}
