import React, { type PropsWithChildren, memo } from 'react';
import { Box, TColor } from '@bridebook/ui';
import { IconChevron } from '@bridebook/ui/src/icons';
import componentStyles from './popover-menu-labeled-trigger.style';

interface IProps extends PropsWithChildren {
  /**
   * Should be passed from the PopoverMenu component.
   */
  isOpened: boolean;
  label?: string;
  iconColor?: TColor;
}

const PopoverMenuLabeledTriggerComp = ({
  label,
  isOpened,
  iconColor = 'space60',
  children,
}: IProps) => {
  const styles = componentStyles();
  return (
    <Box style={styles.wrapper} gap={1}>
      {label && <Box style={styles.label}>{label}</Box>}
      <Box style={styles.valueWrap}>
        <Box style={styles.value}>{children}</Box>
        <IconChevron width={10} color={iconColor} style={styles.chevronWrap(isOpened)} />
      </Box>
    </Box>
  );
};

/**
 * Basic text trigger with a small label for the popover menu.
 */
export const PopoverMenuLabeledTrigger = memo(PopoverMenuLabeledTriggerComp);
