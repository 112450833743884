import { createSelector } from 'reselect';
import { createDeepEqualSelector } from '@bridebook/web/lib/utils/selectors';
import { IApplicationState } from 'lib/types';

const getUsers = (state: IApplicationState) => state.users;
export const getUsersViewer = createSelector(getUsers, (users) => users.viewer);

export const getIsViewerLoaded = createSelector(getUsers, ({ viewerLoaded }) => viewerLoaded);
export const getUsersForm = createSelector(getUsers, ({ form }) => form);
export const getActiveSupplierAccessControl = createSelector(
  getUsers,
  ({ activeSupplierAccessControl }) => activeSupplierAccessControl,
);
export const getExtendedSupplierACL = createSelector(
  getUsers,
  ({ extendedSupplierACL }) => extendedSupplierACL,
);
export const getUserAccessControl = createSelector(
  getUsers,
  ({ userAccessControl }) => userAccessControl,
);

/**
 * IMPORTANT: When using user actions, make sure the viewer is loaded first
 */
const _getUsersActions = createSelector(getUsersViewer, (viewer) => viewer?.actions);
export const getUsersActions = createDeepEqualSelector(_getUsersActions, (actions) => actions);
